import './App.css';
import LeftSidebarMain from './LeftSidebarMain.jsx';
import LeftSidebarFooter from './LeftSidebarFooter.jsx';
import Content from './Content.jsx';

function App() {
	return (
		<div className="App">
			<div className="left--sidebar">
				<LeftSidebarMain />
				<LeftSidebarFooter />
			</div>
			<Content />
		</div>
	);
}

export default App;