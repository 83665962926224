import React from 'react';
import { animate, motion } from "framer-motion";

const container = {
    animate: {
        transition: {
            type: "ease",
            staggerChildren: 0.05
        }
    }
}
  
const item = {
    initial: { x: -8, opacity: 0 },
    animate: { x: 0, opacity: 1 }
}

const subItem = {
    hover: { 
        x: 4,
        transition: {
            type: "ease",
            duration: 0.2
        } 
    }
}

const arrowButton = {
    hover: { 
        rotate: 45,
        transition: {
            type: "ease",
            duration: 0.05
        } 
    }
}

function LeftSidebarFooter() {
  return (
    <motion.div 
        variants={container}
        initial="initial"
        animate="animate"
        className="left--sidebar--footer"
    >
        <motion.div 
            variants={item}
            className="social--item"
        > 
            <motion.div
                variants={subItem}
                whileHover="hover"
                className="social-item--nested"
            >
                <a href="https://dribbble.com/maslenkou" target="_blank" rel="noreferrer">
                    <img src="assets/static/icons/Dribbble.svg" alt="dribble_icon" className="social--icon" />
                    <span>Dribbble</span>
                    <motion.img 
                        variants={arrowButton} 
                        src="assets/static/icons/arrow_forward.svg" 
                        alt="arrow_forward" 
                        className="icon--button--arrow"
                    />
                </a>
            </motion.div>
        </motion.div>
        <motion.div 
            variants={item}
            className="social--item"
        > 
            <motion.div
                variants={subItem}
                whileHover="hover"
                className="social-item--nested"
            >
                <a href="https://read.cv/maslenkou" target="_blank" rel="noreferrer">
                    <img src="assets/static/icons/Readcv.svg" alt="read.cv_icon" className="icon--button" />   
                    <span>Read.cv</span>
                    <motion.img 
                        variants={arrowButton} 
                        src="assets/static/icons/arrow_forward.svg" 
                        alt="arrow_forward" 
                        className="icon--button--arrow"
                    />
                </a>
            </motion.div>
        </motion.div>
        <motion.div 
            variants={item}
            className="social--item"
        > 
            <motion.div
                variants={subItem}
                whileHover="hover"
                className="social-item--nested"
            >
                <a href="https://layers.to/maslenkou" target="_blank" rel="noreferrer">
                    <img src="assets/static/icons/Layers.svg" alt="layers.to_icon" className="icon--button" />
                    <span>Layers.to</span>
                    <motion.img 
                        variants={arrowButton} 
                        src="assets/static/icons/arrow_forward.svg" 
                        alt="arrow_forward" 
                        className="icon--button--arrow"
                    />
                </a>
            </motion.div>
        </motion.div>
        <motion.div 
            variants={item}
            className="social--item"
        > 
            <motion.div
                variants={subItem}
                whileHover="hover"
                className="social-item--nested"
            >                
                <a href="https://www.instagram.com/maslenkou.design/" target="_blank" rel="noreferrer">
                    <img src="assets/static/icons/Instagram.svg" alt="instagram_icon" className="icon--button" />
                    <span>Instagram</span>
                    <motion.img 
                        variants={arrowButton} 
                        src="assets/static/icons/arrow_forward.svg" 
                        alt="arrow_forward" 
                        className="icon--button--arrow"
                    />
                </a>
            </motion.div>
        </motion.div>
        <motion.div 
            variants={item}
            className="social--item"
        > 
            <motion.div
                variants={subItem}
                whileHover="hover"
                className="social-item--nested"
            >
            <a href="https://www.behance.net/maslenkou" target="_blank" rel="noreferrer">
                <img src="assets/static/icons/Behance.svg" alt="behance_icon" className="icon--button" />
                <span>Behance</span>
                <motion.img 
                    variants={arrowButton} 
                    src="assets/static/icons/arrow_forward.svg" 
                    alt="arrow_forward" 
                    className="icon--button--arrow"
                />
            </a>
            </motion.div>
        </motion.div>
        <motion.div 
            variants={item}
            className="social--item"
        > 
            <motion.div
                variants={subItem}
                whileHover="hover"
                className="social-item--nested"
            >
                <a href="https://x.com/maslenkou" target="_blank" rel="noreferrer">
                    <img src="assets/static/icons/X.svg" alt="x_icon" className="icon--button" />
                    <span>X (ex.Twitter)</span>
                    <motion.img 
                        variants={arrowButton} 
                        src="assets/static/icons/arrow_forward.svg" 
                        alt="arrow_forward" 
                        className="icon--button--arrow"
                    />
                </a>
            </motion.div>
        </motion.div>
    </motion.div>
  );
}

export default LeftSidebarFooter;